import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ConfigProvider,
  message,
  Table,
  Pagination,
  Select,
  Input,
  Radio,
} from "antd";
import { ReactSVG } from "react-svg";
import MyRangePicker from "../../../../../components/MyRangePicker";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";
import LoadingSvg from "../../../../../assets/loading.svg";
import MySpin from "../../../../../components/MySpin";
import MyEmpty from "../../../../../components/Empty";
import DropdownSvg from "../../../../../assets/下拉.svg";
import projectApi from "../../../../../apis/project";
import MessageModal from "../../../../../components/MessageModal";

const stringType = ["校对数据错漏", "万得数据错漏", "口径或表述差异"];
const { TextArea } = Input;

const DetailModal = (props) => {
  const [visible, setVisible] = useState(true);

  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [key, setKey] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [amountTime, setAmountTime] = useState([]); // 日期
  const [time, setTime] = useState([]); // 日期
  const [compareTypeList, setCompareTypeList] = useState([]);
  const [compareType, setCompareType] = useState("");
  // 时间转换
  const setDate = (data) => {
    const inputDate = new Date(data);
    inputDate.setDate(inputDate?.getDate() + 1);
    const outputDate = inputDate?.toISOString().split("T")[0];
    return outputDate;
  };

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    // /* 确定触发的回调 */
    // onOk,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    DetailDrawer, // 跳转详情列表
    ManagementList, // 用户名列表
    compareTypeLists,
  } = props;

  const handleCancel = () => {
    if (dataSource.length === 0) {
      destroy();
    } else {
      judgeDataCheck();
    }
  };
  const destroy = () => {
    onCancel && onCancel();
    setVisible(false);

    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  // const handleOk = () => {
  //   onOk && onOk(amountTime);
  //   setVisible(false);
  //   setTimeout(() => {
  //     closeDialog();
  //   }, 500);
  // };

  useEffect(() => {
    setCompareTypeList(compareTypeLists);
    setCompareType(compareTypeLists[0]);
    setTableLoading(true);
    getDetailTaskCheckList(compareTypeLists[0]);
  }, []);

  useEffect(() => {
    if (amountTime.length !== 0) {
      getTaskCheckType();
    }
  }, [amountTime]);

  const getTaskCheckType = () => {
    let params = {
      rule_id: DetailDrawer.rule_id,
    };
    if (amountTime.length !== 0) {
      params.start_time = `${amountTime[0]} 00:00:00`;
      params.end_time = `${setDate(amountTime[1])} 00:00:00`;
    }
    projectApi.getTaskCheckType(params).then((res) => {
      if (res.code === 200) {
        setCompareTypeList(res.data);
        setCompareType(res?.data[0]);
      } else {
        setCompareTypeList([]);
        setCompareType("");
      }
    });
  };

  // 核对结果详情
  const getDetailTaskCheckList = (name, number, size) => {
    let params = {
      rule_id: DetailDrawer.rule_id,
      compare_type: name || compareType || "",
      page: number !== undefined ? number : pageNumber,
      size: size !== undefined ? size : pageSize,
    };
    if (amountTime.length !== 0) {
      params.start_time = `${amountTime[0]} 00:00:00`;
      params.end_time = `${setDate(amountTime[1])} 00:00:00`;
    }
    projectApi
      .getDetailTaskCheckList(params)
      .then((res) => {
        if (res.code === 200) {
          const data = [...res.data.items];
          setDataSource(data);
          // setDefaultData(data);
          setTotal(res.data?.total);
          setPageNumber(res.data?.page);
          setPageSize(res.data?.size);
          handleheader(data);
        } else {
          setDataSource([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const handleMultiSelectChange = (name, value, record, dataSource) => {
    let newData = [...dataSource];
    newData = newData.map((item) => {
      if (item.group_id === record.group_id) {
        item[name] = value;
        item.update = 1;
      }
      return item;
    });
    // console.log(newData, record);
    // setKey((prevKey) => prevKey + 1);
    setDataSource(newData);
  };
  const setwidth = (key) => {
    let width = 200;

    if (
      [
        "保荐机构",
        "会计师事务所",
        "律师事务所",
        "企业名称",
        "事件独立财务顾问",
        "资产评估机构",
        "审计机构",
        "主承销商",
        "实际募资总额(亿元)(非配套融资)",
      ]?.includes(key)
    ) {
      width = 220;
    } else if (["来源"]?.includes(key) || key.includes("代码")) {
      width = 80;
    } else if (["城市"]?.includes(key) || key.includes("时间")) {
      width = 130;
    } else if (["上市板"]?.includes(key)) {
      width = 100;
    }
    return width;
  };
  const setFixd = (key) => {
    if (key === "来源") {
      return "right";
    }
  };
  const is2DArray = (arr) => {
    if (!Array.isArray(arr)) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (!Array.isArray(arr[i])) {
        return false;
      }
    }

    return true;
  };
  const dealWithDataValue = (dataValue) => {
    if (typeof dataValue === "string") {
      return dataValue.replace(/__ERROR$/, "");
    } else if (Array.isArray(dataValue)) {
      return JSON.stringify(dataValue);

      // if (is2DArray(dataValue)) {
      //   return dataValue.flat().join(",");
      // } else {
      //   return dataValue.join(",");
      // }
    } else {
      return dataValue;
    }
  };

  const handleheader = (items) => {
    const columns = [
      {
        title: "比对时间",
        dataIndex: "run_time",
        width: 120,
      },
      {
        title: "对比类型",
        dataIndex: "compare_type",
        width: 160,
      },
    ];
    if (items.length === 0) return setColumns(columns);

    const firstItem = items[0].data;

    const { 来源, ...updatedItem } = firstItem;
    const dynamicColumns = Object.keys(updatedItem).map((key) => ({
      title: key,
      dataIndex: key,
      render: (_, record) => {
        const dataValue = record.data[key];

        return (
          <div
            style={{
              color:
                typeof dataValue === "string" &&
                record?.data[key]?.includes("__ERROR")
                  ? "rgb(254, 84, 95)"
                  : "#535353",
            }}
          >
            {dealWithDataValue(dataValue)}
          </div>
        );
      },
      width: setwidth(key),
    }));

    let data = [
      ...columns,
      ...dynamicColumns,
      {
        title: "来源",
        dataIndex: "source_from",
        fixed: "right",
        width: 90,
      },

      {
        title: "差异类型",
        dataIndex: "diff_type",
        editable: true,
        fixed: "right",
        width: 160,
        onCell: (record, index) => {
          let rowSpan = 1;
          if (index > 0 && record.group_id === items[index - 1].group_id) {
            rowSpan = 0;
          } else {
            let count = 1;
            while (
              index + count < items.length &&
              record.group_id === items[index + count].group_id
            ) {
              count++;
            }
            rowSpan = count;
          }
          return { rowSpan };
        },
        render: (_, record, index) => {
          return (
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              mode="multiple"
              // maxTagCount="responsive"
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              defaultValue={record?.diff_type || []}
              style={{ width: "165px" }}
              options={stringType?.map((item) => ({
                label: item,
                value: item,
              }))}
              onChange={(value) => {
                handleMultiSelectChange("diff_type", value, record, items);
              }}
            ></Select>
          );
        },
      },

      {
        title: "数据是否修正",
        dataIndex: "data_correction",
        editable: true,
        fixed: "right",
        width: 10,
        onCell: (record, index) => {
          let rowSpan = 1;
          if (index > 0 && record.group_id === items[index - 1].group_id) {
            rowSpan = 0;
          } else {
            let count = 1;
            while (
              index + count < items.length &&
              record.group_id === items[index + count].group_id
            ) {
              count++;
            }
            rowSpan = count;
          }
          return { rowSpan };
        },
        render: (_, record, index) => {
          return (
            <Radio.Group
              name="radiogroup"
              defaultValue={record?.data_correction}
              style={{ width: "70px" }}
              onChange={(e) => {
                handleMultiSelectChange(
                  "data_correction",
                  e.target.value,
                  record,
                  items
                );
              }}
            >
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          );
        },
      },
      {
        title: "备注",
        dataIndex: "remark",
        editable: true,
        fixed: "right",
        width: 160,
        onCell: (record, index) => {
          let rowSpan = 1;
          if (index > 0 && record.group_id === items[index - 1].group_id) {
            rowSpan = 0;
          } else {
            let count = 1;
            while (
              index + count < items.length &&
              record.group_id === items[index + count].group_id
            ) {
              count++;
            }
            rowSpan = count;
          }
          return { rowSpan };
        },
        render: (_, record, index) => {
          return (
            <>
              <TextArea
                // showCount

                placeholder="1-170个字符"
                maxLength={170}
                style={{ width: "220px", resize: "vertical" }}
                rows={3}
                defaultValue={record?.remark}
                onBlur={(e) => {
                  const cleanedValue = e.target.value
                    .replace(/\s+/g, " ")
                    .trim();
                  handleMultiSelectChange(
                    "remark",
                    cleanedValue,
                    record,
                    items
                  );
                }}
              />
            </>
          );
        },
      },
    ];

    setColumns(data);
  };

  const pageChange = (page, size) => {
    const hasUpdateField = dataSource.some((item) =>
      item.hasOwnProperty("update")
    );

    if (hasUpdateField) {
      MessageModal({
        type: "warning",
        description: "当前页面存在修改数据未保存，确认切换吗？",
        title: "数据详情保存",
        okText: "确认",
        onOk: () => {
          setPageNumber(page);
          setPageSize(size);
        },
      });
    } else {
      setPageNumber(page);
      setPageSize(size);
    }
  };

  useEffect(() => {
    if (compareType !== "") {
      setTableLoading(true);
      getDetailTaskCheckList();
    }
  }, [pageNumber, pageSize]);

  const judgeDataCheck = () => {
    const hasUpdateField = dataSource.some((item) =>
      item.hasOwnProperty("update")
    );

    if (hasUpdateField) {
      MessageModal({
        type: "warning",
        description: "当前页面存在修改数据，确认离开吗？",
        title: "数据详情",
        okText: "确认",
        onOk: () => {
          destroy();
        },
      });
    } else {
      destroy();
    }
  };
  const updateDetailTaskCheckList = () => {
    const data = dataSource.filter((item) => item.update === 1);
    const groupedData = new Map();
    data.forEach((item) => {
      const groupId = item.group_id;
      if (!groupedData.has(groupId)) {
        groupedData.set(groupId, item);
      }
    });

    const processedData = [];
    for (const item of groupedData.values()) {
      const processedItem = {
        dag_run_id: item.dag_run_id,
        group_id: item.group_id,
        compare_type: item.compare_type,
        remark: item.remark || "",
        data_correction: item.data_correction,
        diff_type: item.diff_type,
      };
      processedData.push(processedItem);
    }

    projectApi.updateDetailTaskCheckList(processedData).then((res) => {
      if (res.code === 200) {
        setTableLoading(true);
        getDetailTaskCheckList();
        setKey((prevKey) => prevKey + 1);
      } else {
        message.info(res.data);
      }
    });
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="messageModal"
        centered
        footer={null}
        width="100%"
        maskClosable={false}
      >
        <div className="DataCheckDetailDrawer">
          <div className="selectTime">
            <div className="name">对比时间 </div>
            <MyRangePicker
              style={{ width: "250px" }}
              onChange={(time, timeString) => {
                setTime(time);
                setAmountTime(timeString);
              }}
              time={time}
            ></MyRangePicker>
            <div className="compareType">对比类型 </div>
            <Select
              placeholder="请选择"
              className="Input"
              // allowClear
              style={{ width: "320px" }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              options={compareTypeList?.map((item) => ({
                label: item,
                value: item,
              }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                setCompareType(e);
              }}
              value={compareType}
            ></Select>

            <Button
              onClick={() => {
                if (tableLoading) {
                  return;
                }

                setTableLoading(true);
                getDetailTaskCheckList(null, 1, 50);
              }}
              style={{
                background: "#016DB4",
                borderColor: "#016DB4",
                color: "#FFFFFF",
                marginLeft: "20px",
              }}
            >
              查询
            </Button>
          </div>

          <div className="allTitle">
            <div className="title">
              <div className="blue">创建人:</div>
              <div className="mr10">
                {
                  ManagementList?.find(
                    (item) => item?.user_id === DetailDrawer.created_by_id
                  )?.username
                }
              </div>
              <div className="blue">执行内容:</div>
              <div className="mr10">{DetailDrawer.execute_content}</div>
              <div className="blue">规则ID:</div>
              <div className="mr10">{DetailDrawer.rule_id}</div>
            </div>
            {dataSource.some((item) => item.hasOwnProperty("update")) ? (
              <Button
                onClick={() => updateDetailTaskCheckList()}
                style={{
                  background: "#016DB4",
                  borderColor: "#016DB4",
                  color: "#FFFFFF",
                  marginTop: "-5px",
                }}
              >
                保存
              </Button>
            ) : null}
          </div>

          <div>
            <Table
              className="dataTaskTable"
              key={key}
              loading={
                tableLoading
                  ? {
                      indicator: (
                        <div style={{ background: "#fff" }}>
                          <ReactSVG
                            src={LoadingSvg}
                            className="anticon-spin anticon myLoadingIcon"
                          />
                        </div>
                      ),
                    }
                  : false
              }
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => {
                return record.data_id;
              }}
              scroll={{
                y: "calc(100vh - 280px)",
                x: "max-content",
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
            />
            {total >= 1 ? (
              <div className="paginationBox">
                <Pagination
                  className="myPagination"
                  showQuickJumper
                  current={pageNumber}
                  total={total}
                  pageSize={pageSize}
                  pageSizeOptions={[10, 20, 50, 100]}
                  onChange={pageChange}
                  showTotal={(total) => `共 ${total} 条`}
                  showSizeChanger={true}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(DetailModal);
